import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import TextField from "../components/TextField";
import db from "../FireBase";
import { collectionGroup, getDocs } from "firebase/firestore";

const ActiveMemberInput = ({
  memberEmail,
  setMemberEmail,
  setPaymentOption,
}) => {
  const confirmMembershipBooking = async () => {
    console.log("Confirming membership...");

    const querySnapshot = await getDocs(collectionGroup(db, bookings));

    var bookingsPriorFiltering = querySnapshot.docs.map((doc) => doc.data());

    const existingBookings = bookingsPriorFiltering.filter(
      (value) => Object.keys(value).length !== 0
    );

    // Converting people and duration to numbers
    existingBookings.forEach(
      (o, i, a) =>
        (a[i] = {
          ...o,
          people: parseInt(o["people"]),
          duration: parseInt(o["duration"]),
        })
    );

    console.log("existingBookings: ", existingBookings);
  };

  return (
    <>
      <View className="flex-row items-center justify-between mb-3 bg-orange p-4">
        <TextField
          title="Email:"
          placeholder="GO_PACERS@GMAIL.COM"
          value={memberEmail}
          setValue={setMemberEmail}
        />
      </View>
      <View className="flex-row m-1 justify-between">
        <TouchableOpacity
          className="h-16 w-44 justify-center bg-black items-center mr-1"
          onPress={() => setPaymentOption(null)}
        >
          <Text className=" text-white font-BobbyJones text-xl">Go Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          className="h-16 w-44 justify-center bg-black items-center"
          onPress={confirmMembershipBooking}
        >
          <Text className=" text-white font-BobbyJones text-xl">Continue</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default ActiveMemberInput;
