import * as React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Page from "../components/Page";
import { useEffect, useState } from "react";
import { ref, getBytes } from "firebase/storage";
import { storage } from "../FireBase";

const PricingSection = ({ title, details, price, navigation }) => {
  console.log("title", title);
  return (
    <View className="w-10/12 items-center mb-14 max-w-lg">
      <View className="bg-orange w-2/3 p-2 rounded-tl-md rounded-tr-md items-center">
        <Text className=" text-[#fcefde] font-BobbyJones text-lg">{title}</Text>
      </View>
      <View className="bg-[#fcefde] w-full p-2 rounded-md items-center">
        <Text className=" text-black font-BobbyJones text-sm text-center">
          {details.includes("*")
            ? details
                .split("*")
                .filter((n) => n)
                .map((listItem, i) => {
                  return (
                    <Text key={i}>
                      {listItem}
                      {"\n"}
                    </Text>
                  );
                })
            : details}
        </Text>
        {price && (
          <View className="flex-row items-center justify-center">
            <View className=" w-20 h-0.5 bg-black"></View>
            <Text className=" text-black font-BobbyJones text-lg ml-2 mr-2">
              {price}
            </Text>
            <View className="w-20 h-0.5 bg-black"></View>
          </View>
        )}

        {title == "Membership" && (
          <TouchableOpacity
            className="flex h-16 w-3/4 justify-center m-2 p-4 bg-black mt-4"
            onPress={() => navigation.navigate("Membership")}
          >
            <View className="flex-1 justify-center items-center">
              <Text className="text-white font-BobbyJones text-xl">
                Become a Member
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default function Pricing({ navigation }) {
  const [pricingData, setPricingData] = useState(null);

  const getPricingData = async () => {
    const csvtojson = require("csvtojson");

    const env = !window.location.href.includes("saunasocial.co")
      ? "sauna-social-dev"
      : "sauna-social";

    const fileRef = ref(storage, `gs://${env}.appspot.com/pricing.csv`);

    const res = await getBytes(fileRef);
    const enc = new TextDecoder("utf-8");
    const csvStr = enc.decode(res);

    var csvAsJson;
    await csvtojson()
      .fromString(csvStr)
      .then((val) => {
        csvAsJson = val;
      });

    console.log("Pricing Data: ", csvAsJson);

    setPricingData(csvAsJson);
  };

  useEffect(() => {
    getPricingData();
  }, []);

  return (
    <Page
      navigation={navigation}
      body={
        <View className="flex justify-between items-center mb-10 mt-10">
          {!pricingData && (
            <Text className="font-BobbyJones text-2xl"> Loading ...</Text>
          )}
          {pricingData &&
            pricingData.map((info, i) => {
              return (
                <PricingSection
                  key={i}
                  title={info.title}
                  details={info.details}
                  price={info.price}
                  navigation={navigation}
                />
              );
            })}
        </View>
      }
    />
  );
}
