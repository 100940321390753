import React, { useEffect, useState } from "react";
import { View, Text, Linking } from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import Page from "../components/Page";
import { collection, query, where, getDocs } from "firebase/firestore";
import db from "../FireBase";

export default function Location({ navigation }) {
  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    const fetchLocationData = async () => {
      const todayISOString = new Date().toISOString().slice(0, 10);
      const q = query(
        collection(db, "busLocation"),
        where("date", "==", todayISOString)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        setLocationData(docData); // Assuming docData contains location information
      }
    };

    fetchLocationData();
  }, []);

  const openGoogleMaps = () => {
    const address = locationData?.text.replace(/ /g, "+");
    const url = `https://www.google.com/maps/search/?api=1&query=${address}`;
    Linking.openURL(url);
  };

  return (
    <Page
      navigation={navigation}
      body={
        <View className="flex-1 justify-center items-center">
          {" "}
          {/* Centering Container */}
          <View className="flex justify-center items-center mt-32 mx-4 p-2 border-8 border-[#fcefde] rounded-2xl mb-7 max-w-4xl bg-orange">
            {locationData ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text className="font-BobbyJones text-xl text-center">
                  Bus is currently at:{"\n"}
                  <Text
                    onPress={openGoogleMaps}
                    className="underline text-gray-700"
                    style={{ textDecorationLine: "underline" }}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size={16}
                      color="rgb(55 65 81)" // or any color you prefer
                      onPress={openGoogleMaps}
                      style={{ marginRight: 10 }}
                    />
                    {locationData.text}
                  </Text>
                </Text>
              </View>
            ) : (
              <Text className="font-BobbyJones text-xl text-center">
                Current location is unknown... :(
              </Text>
            )}
          </View>
        </View>
      }
    />
  );
}
