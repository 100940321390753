import { View, Text } from "react-native";
import { Picker } from "@react-native-picker/picker";
import React from "react";

const PickerField = ({ title, value, setValue, options }) => {
  return (
    <View className="flex-row items-center justify-between mt-3">
      <Text className="text-2xl font-BobbyJones mr-2 text-[#fcefde]">
        {title}
      </Text>
      <Picker
        selectedValue={value}
        onValueChange={(val) => setValue(val)}
        style={{
          width: "18rem",
          height: "2rem",
          paddingLeft: "12px",
          backgroundColor: "#fcefde",
          color: "black",
          outline: "none",
          fontSize: "18px",
          border: "none",
          fontWeight: "bold",
          borderRadius: 0,
        }}
      >
        {options.map((e, i) => {
          return <Picker.Item key={i} label={e} value={e} />;
        })}
      </Picker>
    </View>
  );
};

export default PickerField;
