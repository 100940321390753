import { View, Text, TextInput } from "react-native";
import React from "react";

const TextField = ({ title, placeholder, value, setValue, textSize }) => {
  return (
    <View className="flex-row items-center mt-3 justify-evenly">
      <Text className={`${textSize} font-BobbyJones mr-2 text-[#fcefde]`}>
        {title}
      </Text>
      <TextInput
        onChangeText={setValue}
        value={value}
        placeholder={placeholder}
        keyboardType="default"
        placeholderTextColor="#757575"
        className=" w-72 h-8 p-4 pl-4 font-BobbyJones"
        style={{
          backgroundColor: "#fcefde",
          outline: "none",
          fontSize: "18px",
        }}
      />
    </View>
  );
};

TextField.defaultProps = {
  textSize: "text-2xl",
};

export default TextField;
