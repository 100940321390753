function InstagramFeed() {
  const isMobile = window.innerWidth <= 768;
  const height = isMobile ? "35vh" : "110vh";
  const marginTop = isMobile ? "20px" : "50px";
  return (
    <div style={{ marginTop }}>
      <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
      <iframe
        src="https://cdn.lightwidget.com/widgets/47efd88fd58f5303aa6182997590c28e.html"
        scrolling="no"
        allowtransparency="true"
        className="lightwidget-widget"
        style={{ width: "100%", border: 0, height }}
      ></iframe>
    </div>
  );
}

export default InstagramFeed;
