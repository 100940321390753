import * as React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Table, Row, Rows } from "react-native-table-component";

const SaunaTable = ({ title, header, data }) => {
  console.log("DATA:", data);
  const styles = StyleSheet.create({
    HeadStyle: {
      height: 50,
      alignContent: "center",
      backgroundColor: "#F69320",
      textAlign: "center",
    },
    TableText: {
      margin: 10,
      backgroundColor: "#000",
    },
    RowStyle: {
      backgroundColor: "#fff",
      textAlign: "center",
      alignContent: "center",
      justifyContent: "center",
    },
  });

  return (
    <View className="mx-4 p-2 border-8 border-[#fcefde] rounded-2xl my-7 w-90">
      <Text className="font-BobbyJones text-black mb-3 text-2xl">
        {title}
        {"\n"}
      </Text>

      {data?.length ? (
        <Table borderStyle={{ borderWidth: 1, borderColor: "#000" }}>
          <Row
            data={header}
            style={styles.HeadStyle}
            textStyle={styles.TableText}
          />
          <Rows
            data={data}
            textStyle={styles.TableText}
            style={styles.RowStyle}
            className="itemsCenter"
          />
        </Table>
      ) : (
        <Text className="font-BobbyJones text-black mb-3 text-sm">
          No data to display :(
        </Text>
      )}
    </View>
  );
};

export default SaunaTable;
