import { View, Text } from "react-native";
import PhoneInput from "react-native-phone-number-input";

import React from "react";

const PhoneField = ({ reference, value, setValue }) => {
  return (
    <View className="flex-row items-center justify-between mt-3">
      <Text className="text-2xl font-BobbyJones mr-2 text-[#fcefde]">
        Phone:
      </Text>
      <PhoneInput
        ref={reference}
        defaultValue={value}
        defaultCode="US"
        layout="first"
        international={false}
        onChangeText={(text) => setValue(text)}
        // onChangeFormattedText={(text) => {
        //   setFormattedPhoneNumber(text);  // Adds country code
        // }}
        containerStyle={{
          width: "18rem",
          padding: 0,
        }}
        countryPickerButtonStyle={{
          display: "none",
        }}
        textContainerStyle={{
          width: "60%",
          height: "2rem",
          backgroundColor: "#fcefde",
          paddingLeft: "16px",
          maxLength: 10,
        }}
        codeTextStyle={{ display: "none" }}
        placeholder="xxxxxxxxxx"
        textInputStyle={{
          background: "#fcefde",
          // color: "#757575"
          outline: "none",
          fontFamily: "BobbyJones",
          fontSize: "18px",
          maxLength: 10,
        }}
      />
    </View>
  );
};

export default PhoneField;
