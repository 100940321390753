import { TouchableOpacity, Text } from "react-native";
import React from "react";

const DeleteAdminButton = ({
  deleteDocument,
  confirmationPrompt,
  coll,
  objId,
}) => {
  return (
    <TouchableOpacity
      className="w-full h-full bg-red-500 text-center"
      onPress={() => {
        var proceed = confirm(confirmationPrompt);
        if (proceed) {
          console.log("Deleting ", objId, " from ", coll);
          deleteDocument(objId, coll);
        }
      }}
    >
      <Text
        className="text-black items-center align-middle font-extrabold text-xl pt-3
         h-16"
      >
        x
      </Text>
    </TouchableOpacity>
  );
};

export default DeleteAdminButton;
