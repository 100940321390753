import * as React from "react";
import { View, Text } from "react-native";
import Page from "../components/Page";
import db from "../FireBase";
import {
  addDoc,
  getDocs,
  collection,
  collectionGroup,
  query,
  where,
} from "firebase/firestore";

import emailjs from "@emailjs/browser";
import BookingMetadata from "../components/BookingMetadata";

export default function BookingConfirmation({ route, navigation }) {
  console.log("route: ", route);
  const {
    confirmation,
    name,
    email,
    phone,
    date,
    time,
    people,
    duration,
    newMember,
  } = route.params;

  // Function to fetch location data for the current date
  const fetchLocationForDate = async (date) => {
    console.log(
      `Preparing to query 'busLocation' collection where 'date' == '${date}'`
    );

    const querySnapshot = await getDocs(
      query(collection(db, "busLocation"), where("date", "==", date))
    );
    const locations = querySnapshot.docs.map((doc) => doc.data());
    return locations.length > 0 ? locations[0] : null;
  };

  const createNewBooking = async () => {
    try {
      const bookingDetails = {
        name: name,
        email: email,
        phoneNumber: phone,
        date: date,
        time: time,
        people: people,
        duration: duration,
        confirmation: confirmation,
      };

      const matchingConfirmation = await getMatchingConfirmation();

      // if (matchingConfirmation.length > 0) {
      //   console.log(
      //     "Booking already exists matching confirmation: ",
      //     confirmation,
      //     ". All detais: ",
      //     matchingConfirmation
      //   );
      //   return;
      // }
      console.log("Storing new booking: ", bookingDetails);
      const docRef = await addDoc(collection(db, `bookings`), bookingDetails);
      console.log("Document written with ID: ", docRef.id);

      sendConfirmationEmail();
    } catch (error) {
      // TODO: return failure page, try again
      console.log("Failed to create new booking", error);
    }
  };

  const createNewMembership = async () => {
    try {
      const matchingMembership = await getMatchingMembership();

      if (matchingMembership.length > 0) {
        console.log(
          "Membership already exists matching email: ",
          email,
          ". All detais: ",
          matchingMembership
        );
        return;
      }

      const membershipRecord = {
        name: name,
        email: email.toLowerCase(),
        phoneNumber: phone,
        date: new Date().toLocaleDateString(),
      };
      console.log("Adding new membership record: ", membershipRecord);

      const docRef = await addDoc(
        collection(db, `activeMembers`),
        membershipRecord
      );
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      // TODO: return failure page, try again
      console.log("Failed to create new membership", error);
    }
  };

  const sendConfirmationEmail = async () => {
    // Parse the date string "1/2/2023" to a Date object
    console.log("Converting date format: ", date);
    const [month, day, year] = date.split("/");
    const formattedDate = new Date(year, month - 1, day); // Months are 0-indexed in JavaScript Date

    // Convert the Date object to an ISO string and then slice it to get the "YYYY-MM-DD" format
    const isoFormattedDate = formattedDate.toISOString().slice(0, 10);
    console.log("new date format: ", isoFormattedDate);

    // Use the isoFormattedDate for fetching the location
    const locationData = await fetchLocationForDate(isoFormattedDate);
    let locationMessage = locationData
      ? locationData.text
      : "Will be sent day before or morning of the session.";

    console.log("Sending confirmation email to customer.");
    emailjs.send(
      "service_80nv3nh",
      "template_ees2beo",
      {
        date: date, // 1/13/2024
        name: name,
        email: email,
        time: time,
        people: people,
        duration: duration,
        location: locationMessage,
      },
      "j0sr38ITlWYjkQ7Z7"
    );
  };

  React.useEffect(() => {
    console.log(
      "CONFIMRATION RECEIVED: ",
      confirmation,
      "name ",
      name,
      "email ",
      email,
      "phone ",
      phone,
      "date ",
      date,
      "time ",
      time,
      "people ",
      people,
      "duration ",
      duration,
      "newMember ",
      newMember
    );

    // https://saunasocial.vercel.app/BookingConfirmation?name=&email=kv@gmial.com&phone=&date=&time=&people=&duration=&newMember=true&confirmation=cs_test_b17zeIRFMWyTURr6hYW6y58PIW1J5b0dZuC22fgky6FDmdZ138m3MhXxm2

    if (newMember && JSON.parse(newMember)) {
      createNewMembership();
    } else if (
      confirmation &&
      name &&
      email &&
      phone &&
      date &&
      time &&
      people &&
      duration
    ) {
      createNewBooking();
    } else {
      alert("Did not receive all expected params, something went wrong...");
    }
  }, []);

  return (
    <Page
      navigation={navigation}
      confetti
      body={
        <View className="flex items-center mt-20">
          {JSON.parse(newMember) ? (
            <>
              <Text className="text-black font-BobbyJones text-2xl font-bold mb-2">
                Your membership has beem confirmed!
              </Text>
              <Text className="text-sm font-BobbyJones">
                Please note that a booking has not been created yet.
              </Text>

              <Text className="text-sm font-BobbyJones">
                Please create a new booking to apply your new membership.
              </Text>

              <Text className="text-sm mb-2 font-BobbyJones">
                To apply multiple membership discounts, each member must create
                their own booking.
              </Text>
            </>
          ) : (
            <>
              <Text className="text-black font-BobbyJones text-2xl font-bold mb-2">
                Your booking has been confirmed.
              </Text>
              <Text className="text-sm font-BobbyJones">
                Get ready to sweat your a** off!
              </Text>

              <Text className="text-sm mb-2 font-BobbyJones">
                We have sent out an email confirmation.
              </Text>
            </>
          )}

          <BookingMetadata
            name={name}
            email={email}
            date={date}
            startTime={time}
            duration={duration}
            desiredPeople={people}
            phoneNumber={phone}
          />
        </View>
      }
    />
  );

  async function getMatchingConfirmation() {
    console.log("Checking if confirmation already exists: ", confirmation);
    const querySnapshot = await getDocs(collectionGroup(db, "bookings"));

    const bookings = querySnapshot.docs.map((doc) => doc.data());

    const matchingConfirmation = bookings.filter(
      (booking) =>
        Object.keys(booking).length !== 0 &&
        booking.confirmation == confirmation
    );
    return matchingConfirmation;
  }

  async function getMatchingMembership() {
    console.log("Checking if membership already exists: ", email);
    const querySnapshot = await getDocs(collectionGroup(db, "activeMembers"));

    const memberships = querySnapshot.docs.map((doc) => doc.data());

    const matchingMembership = memberships.filter(
      (membership) =>
        Object.keys(membership).length !== 0 &&
        membership.email == email.toLowerCase()
    );
    return matchingMembership;
  }
}
