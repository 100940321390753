import * as React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import db from "../FireBase";
import { doc, setDoc } from "firebase/firestore";
import DateField from "./DateField";
import SaunaTable from "./SaunaTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DateFieldAdminForm = ({
  whitelist,
  blacklist,
  refreshDateList,
  allAvailableDates,
}) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const storeInDb = async (coll) => {
    const dateToStore = selectedDate.toISOString().slice(0, 10);

    var proceed = confirm(`Are you sure you want to store ${dateToStore}?`);
    if (proceed) {
      const toStore = {
        date: dateToStore,
      };
      console.log(`Storing date in ${coll} - ${dateToStore}`);
      await setDoc(doc(db, coll, dateToStore), toStore);

      refreshDateList();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <View className="mx-4 p-2 border-8 border-[#fcefde] rounded-2xl my-7 w-90">
        <Text className="font-BobbyJones text-black mb-3 text-2xl">
          Date Blacklist/Whitelist:
        </Text>
        <View className="w-24 mb-4">
          {allAvailableDates && (
            <DateField
              date={selectedDate}
              setDate={setSelectedDate}
              whitelistWeekendsOnly={false}
              allAvailableDates={allAvailableDates}
            />
          )}
        </View>

        <TouchableOpacity
          className="h-16 bg-black text-center mb-4"
          onPress={() => storeInDb("blacklistDates")}
        >
          <Text
            className="text-white items-center align-middle font-extrabold text-lg pt-3
             h-16 mb-2"
          >
            Blacklist -{" "}
            {selectedDate && selectedDate.toISOString().slice(0, 10)}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          className="h-16 bg-black text-center"
          onPress={() => storeInDb("whitelistDates")}
        >
          <Text
            className="text-white items-center align-middle font-extrabold text-lg pt-3
             h-16 border-white"
          >
            Whitelist -{" "}
            {selectedDate && selectedDate.toISOString().slice(0, 10)}
          </Text>
        </TouchableOpacity>
        <Text className="font-BobbyJones text-sm m-3">
          Blacklist = disable{"\n"}
          Whitelist = enable{"\n"}
          By default, only weekends are enabled
        </Text>
        <SaunaTable
          data={whitelist}
          header={["Email", "Delete?"]}
          title="Whitelist:"
        />
        <SaunaTable
          data={blacklist}
          header={["Email", "Delete?"]}
          title="Blacklist:"
        />
      </View>
    </LocalizationProvider>
  );
};

export default DateFieldAdminForm;
