import React from "react";
import { View } from "react-native";
import { SocialIcon } from "react-social-icons";

const SaunaSocialIcon = ({ url }) => {
  return (
    <SocialIcon
      url={url}
      bgColor="#191919"
      fgColor="#fff"
      style={{ margin: 6, position: "sticky" }}
    />
  );
};

const Footer = () => {
  return (
    <View className="flex-row w-full h-8 justify-center fixed top-16">
      <SaunaSocialIcon url="https://www.instagram.com/saunasocial/?hl=en" />
      {/* <SaunaSocialIcon url="https://twitter.com/jaketrent" />
      <SaunaSocialIcon url="https://www.tiktok.com/@smackinheat" /> */}
    </View>
  );
};

export default Footer;
