import * as React from "react";
import { TextField, Button, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import db from "../FireBase";
import SaunaTable from "./SaunaTable";

const LocationAdminForm = () => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [textInput, setTextInput] = React.useState("");
  const [tableData, setTableData] = React.useState([]);

  const getTodayISOString = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of the day
    return today.toISOString().slice(0, 10);
  };

  const deleteLocation = async (date) => {
    await deleteDoc(doc(db, "busLocation", date));
    fetchTableData(); // Refresh the list after deletion
  };

  const handleSubmit = async () => {
    if (!textInput.trim()) {
      alert("Please enter a location.");
      return;
    }

    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      // Set the time to the start of the day to avoid timezone issues
      currentDate.setHours(0, 0, 0, 0);
      const formattedDate = currentDate.toISOString().slice(0, 10);

      await setDoc(doc(db, "busLocation", formattedDate), {
        date: formattedDate,
        text: textInput,
      });

      // Increment the date by one day
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }

    fetchTableData();
  };

  const fetchTableData = async () => {
    const todayISOString = getTodayISOString();
    const q = query(
      collection(db, "busLocation"),
      where("date", ">=", todayISOString)
    );
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => {
      const docData = doc.data();
      return [
        docData.date,
        docData.text,
        <Button onClick={() => deleteLocation(doc.id)}>Delete</Button>, // delete button
      ];
    });
    console.log("LOCATION DATA: ", data);
    setTableData(data);
  };

  React.useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <Box margin={2}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box margin={2}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box margin={2}>
          <TextField
            label="Bus Location"
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
          />
        </Box>
        <Box margin={2}>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>

        <SaunaTable
          data={tableData}
          header={["Date", "Location", "Delete?"]}
          title="Bus Location:"
        />
      </div>
    </LocalizationProvider>
  );
};

export default LocationAdminForm;
