import { View, Text } from "react-native";
import React from "react";

const BookingMetadata = ({
  name,
  email,
  date,
  startTime,
  duration,
  desiredPeople,
  phoneNumber,
}) => {
  const details = {
    "Name:": name,
    "Email:": email,
    "Date:": date,
    "Time:": startTime,
    "Duration:": duration ? `${duration} mins` : "", // Ensure 'duration' has a value before appending 'mins'
    "People:": desiredPeople,
    "Phone Number:": phoneNumber,
  };

  return (
    <View className="flex flex-col p-2 bg-orange mt-4 w-96">
      {Object.entries(details).map(([key, value]) => {
        // Check if the value is truthy and not just whitespace
        if (value && String(value).trim() !== "") {
          return (
            <View
              key={key}
              className="flex-row items-center justify-between my-1"
            >
              <Text className="text-lg font-BobbyJones mr-2 text-black">
                {key}
              </Text>
              <Text className="text-lg font-BobbyJones mr-2 text-black">
                {value}
              </Text>
            </View>
          );
        }
        return null; // Return null if there's no value, so nothing gets rendered
      })}
    </View>
  );
};

export default BookingMetadata;
