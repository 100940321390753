import * as React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import Page from "../components/Page";
import Footer from "../components/Footer";
import InstagramFeed from "../components/InstagramFeed";

function ButtonText({ title }) {
  return (
    <Text className="text-3xl text-white font-BobbyJones m-2 text-center">
      {title}
    </Text>
  );
}

export default function Home({ navigation }) {
  return (
    <Page
      navigation={navigation}
      body={
        <View className="h-full overflow-y-auto">
          {/* Slogan */}
          <View className="items-center mt-4">
            <View className="h-2 w-60 bg-black" />

            <Text className="text-4xl font-BobbyJones m-2">
              stop, sweat, breathe
            </Text>

            <View className="h-2 w-60 bg-black" />
          </View>

          {/* Button Grid */}
          <View className="items-center">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={1}
              style={{
                width: "550px",
                maxWidth: "550px",
                marginTop: "24px",
              }}
              // className=" w-24"
            >
              <Grid item xs={12} container justifyContent="center">
                <TouchableOpacity
                  onPress={() => navigation.navigate("Booking")}
                  className="bg-black justify-center h-20 w-2/3  mt-4"
                >
                  <ButtonText title="Book a session" />
                </TouchableOpacity>
              </Grid>

              <Grid item xs={6} container justifyContent="flex-end">
                <TouchableOpacity
                  onPress={() => navigation.navigate("EventsAndDeals")}
                  className=" bg-orange justify-center h-28 w-2/3"
                >
                  <ButtonText title="Events / Deals" />
                </TouchableOpacity>
              </Grid>
              <Grid item xs={6} justifyContent="flex-start">
                <TouchableOpacity
                  onPress={() => navigation.navigate("OurStory")}
                  className=" bg-black justify-center h-28 w-2/3"
                >
                  <ButtonText title="Our Story" />
                </TouchableOpacity>
              </Grid>

              <Grid
                item
                xs={8}
                container
                justifyContent="center"
                // width={}
                style={{ justifyContent: "flex-end" }}
              >
                <TouchableOpacity
                  onPress={() => navigation.navigate("ContactUs")}
                  className="bg-black justify-center h-20 w-3/4 flex "
                >
                  <ButtonText title="Contact Us" />
                </TouchableOpacity>
              </Grid>

              <Grid item xs={4}>
                <TouchableOpacity
                  onPress={() => navigation.navigate("ComingSoon")}
                  className="bg-orange flex justify-center h-20  w-2/4 items-center"
                >
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{
                      height: "2rem",
                      width: "2rem",
                      color: "white",
                    }}
                  />
                </TouchableOpacity>
              </Grid>
            </Grid>
          </View>
          {/* <View className="flex w-full h-full"></View> */}
          <InstagramFeed />

          {/* <Footer /> */}
        </View>
      }
    />
  );
}
