import { StyleSheet } from "react-native";

const theme = {
  orange: "#F69320",
  black: "#191919",
  green: "#C9D6B4",
  cream: "#fcefde",
};

const styles = StyleSheet.create({
  headerContainer: {
    height: 50,
    marginTop: 60,
    width: "90%",
    borderColor: theme.black,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  headerText: {
    fontFamily: "Bobby-Jones-Soft",
    fontSize: 34,
  },
  slogan: {
    fontSize: 45,
    textAlign: "center",
    fontFamily: "Bobby-Jones-Soft",
  },
  divider: {
    height: 7,
    width: 340,
    backgroundColor: theme.black,
  },
  logo: {
    width: 120,
    height: 120,
    margin: 5,
  },
  menuIcon: {
    height: 20,
    width: 20,
  },
  socialIcon: {
    height: 50,
    width: 50,
    color: theme.orange,
  },

  locationIcon: {
    height: 40,
    width: 40,
    color: "#fff",
    margin: "auto",
  },
  locationButton: {
    backgroundColor: theme.orange,
    width: "100%",
    height: 90,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
  },
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: theme.green,
    height: "100%",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    marginTop: "0%",
    marginTop: 60,
    fontFamily: "Bobby-Jones-Soft",
  },
  calendar: {
    height: 350,
    minWidth: 400,
    maxWidth: 500,
    marginTop: 1200,
  },
  homeButton: {
    borderRadius: 5,
    backgroundColor: theme.black,
    color: theme.green,
    padding: "18px 36px",
    fontSize: "18px",
    margin: 10,
    height: 50,
    width: 300,
    fontFamily: "Bobby-Jones-Soft",
  },
  confirmBookingButton: {
    width: 350,
    height: 50,
    backgroundColor: theme.black,
    borderRadius: 5,
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    marginTop: 30,
  },
  bookSessionButton: {
    width: "100%",
    height: 90,
    backgroundColor: theme.black,
    borderRadius: 5,
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  currentDealButton: {
    width: "100%",
    height: 160,
    backgroundColor: theme.black,
    borderRadius: 5,
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    backgroundColor: theme.orange,
  },
  buttonText: {
    fontSize: 28,
    color: "white",
    fontFamily: "Bobby-Jones-Soft",
    margin: 10,
  },
  footer: {
    flexDirection: "row",
    height: 30,
    width: 180,
    position: "absolute",
    bottom: 40,
    justifyContent: "space-between",
  },
  ourStoryContainer: {
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    marginTop: 0,
    borderRadius: 10,
    borderWidth: 6,
    borderColor: "#fff",
    width: "90%",
    height: "65%",
    textAlign: "left",
    padding: 25,
    overflow: "hidden",
    wordWrap: "break-word",
  },
  ourStory: {
    fontFamily: "Bobby-Jones-Soft",
    fontSize: 12,
  },
});

export { styles, theme };
