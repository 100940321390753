import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import BookingMetadata from "./BookingMetadata";
import MembershipOptions from "./MembershipOptions";
import ActiveMemberInput from "./ActiveMemberInput";
import PRICING_IDS from "../price_ids.json";

const ACTIVE_MEMBER_PAYMENT_OPTION = "active member";
const BECOME_A_MEMBER_PAYMENT_OPTION = "become a member";

const PaymentForm = (state) => {
  const {
    navigation,
    name,
    email,
    phoneNumber,
    date,
    desiredPeople,
    isValidMember,
    startTime,
    duration,
    setLoading,
    confirmedDetails,
    setConfirmedDetails,
  } = state;
  const [paymentOption, setPaymentOption] = useState(null);
  const [peopleToPayFor, setPeopleToPayFor] = useState(null);

  const isTesting = !window.location.href.includes("saunasocial.co");

  useEffect(() => {
    console.log("isValidMember", isValidMember);
    const ppl = isValidMember ? desiredPeople - 1 : desiredPeople;
    console.log("People to pay for: ", ppl);
    setPeopleToPayFor(ppl);
  }, []);

  useEffect(() => {
    console.log("isValidMember", isValidMember);
    const ppl = isValidMember ? desiredPeople - 1 : desiredPeople;
    console.log("People to pay for: ", ppl);
    setPeopleToPayFor(ppl);
  }, [isValidMember]);

  const handlePayment = async (paymentId) => {
    // Calling the payment link provided
    setLoading(true);
    console.log("Handling payment using ID: ", paymentId);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      paymentId: paymentId,
      name: name,
      email: email,
      phone: phoneNumber,
      date: date?.toLocaleDateString(),
      time: startTime,
      people: desiredPeople,
      duration: duration,
      newMember: false,
      clientHost: window.location.hostname,
    });

    console.log("Request Body: ", raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const funcUrl = isTesting
      ? "https://sshandlebookingdev.azurewebsites.net/api/sshandlebookingdev"
      : `https://sshandlebooking.azurewebsites.net/api/sshandlebooking`;

    fetch(funcUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("Payment link creation response: ", result);
        const url = JSON.parse(result)["url"];
        window.location.href = url; // Proceeding to Stripe
      })
      .catch((error) => {
        alert(
          "Uh oh... something went wrong. Please try again or contact support. Error: ",
          error
        );
        setLoading(false);
      });
  };

  return (
    <>
      <View className=" w-96">
        {isValidMember ? (
          <Text className=" font-bold font-BobbyJones text-lg mb-1">
            {peopleToPayFor == 0
              ? "No payment needed"
              : `Payment needed for remaining ${peopleToPayFor}`}
          </Text>
        ) : (
          <Text className=" font-bold font-BobbyJones text-lg mb-1 underline">
            No membership found for {email}
          </Text>
        )}

        <BookingMetadata
          name={name}
          email={email}
          date={date?.toLocaleDateString()}
          startTime={startTime}
          duration={duration}
          desiredPeople={desiredPeople}
          phoneNumber={phoneNumber}
        />

        {/* Payment Options */}
        {!paymentOption && (
          <View>
            {peopleToPayFor > 0 && (
              // Either
              <>
                <TouchableOpacity
                  className="h-12 w-full bg-[#fcefde] justify-center mt-2 mb-2 border-black border-2"
                  onPress={() =>
                    handlePayment(
                      PRICING_IDS[isTesting ? "test" : "prod"][
                        duration.toString()
                      ][peopleToPayFor.toString()]
                    )
                  }
                >
                  <Text className=" text-black font-BobbyJones text-xl">
                    Proceed to Checkout
                  </Text>
                </TouchableOpacity>

                {!isValidMember && (
                  <TouchableOpacity
                    className="h-12 w-full bg-[#fcefde] justify-center mb-2 border-black border-2"
                    onPress={() => navigation.navigate("Membership")}
                  >
                    <Text className=" text-black font-BobbyJones text-xl">
                      Become a member today!
                    </Text>
                  </TouchableOpacity>
                )}
              </>
            )}

            {isValidMember && peopleToPayFor == 0 && (
              <TouchableOpacity
                className="h-12 w-full bg-[#fcefde] justify-center mt-2 mb-2"
                onPress={() =>
                  navigation.navigate("BookingConfirmation", {
                    name: name,
                    email: email,
                    phone: phoneNumber,
                    date: date.toLocaleDateString(),
                    people: desiredPeople,
                    newMember: false,
                    time: startTime,
                    duration: duration,
                    confirmation: `EXISTING_MEMBER_${Math.random()
                      .toString(36)
                      .slice(2)}`,
                  })
                }
              >
                <Text className=" text-black font-BobbyJones text-xl">
                  Confirm Booking
                </Text>
              </TouchableOpacity>
            )}
          </View>
        )}

        {/* Active Member Payment Option - NO LONGER USED*/}
        {paymentOption && paymentOption == ACTIVE_MEMBER_PAYMENT_OPTION && (
          <ActiveMemberInput
            memberEmail={memberEmail}
            setMemberEmail={setMemberEmail}
            setPaymentOption={setPaymentOption}
          />
        )}

        {paymentOption === BECOME_A_MEMBER_PAYMENT_OPTION && (
          <MembershipOptions handlePayment={handlePayment} />
        )}

        {confirmedDetails && (
          <TouchableOpacity
            className="h-12 w-full justify-center bg-black"
            onPress={() => {
              setConfirmedDetails(false);
              setPaymentOption(null);
            }}
          >
            <Text className=" text-[#fcefde] font-BobbyJones text-lg">
              Go Back
            </Text>
            {/* TODO: button broken */}
          </TouchableOpacity>
        )}
      </View>
    </>
  );
};

export default PaymentForm;
