import * as React from "react";
import { useEffect } from "react";
// import { View, Text } from "react-native";
// import Page from "../components/Page";

export default function GiftVouchers({ navigation }) {
  useEffect(() => {
    window.location.href = "https://saunasocial.giftpro.co.uk/";
  }, []);

  return <></>;
}
