import * as React from "react";
import { View, Text, TouchableOpacity, CheckBox } from "react-native";
import Page from "../components/Page";
import db from "../FireBase";
import {
  collectionGroup,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { List } from "react-native-paper";
import moment from "moment";
import SaunaTable from "../components/SaunaTable";
import LocationAdminForm from "../components/LocationAdminForm";
import CouponTable from "../components/CouponTable";
import DateFieldAdminForm from "../components/DateFieldAdminForm";
import DeleteAdminButton from "../components/DeleteAdminButton";
import { theme } from "../styles";

export default function Admin({ navigation }) {
  const [todaysBookings, setTodaysBookings] = React.useState([]);
  const [done, setDone] = React.useState(false);

  const [upcomingBookings, setUpcomingBookings] = React.useState([]);
  const [activeMembers, setActiveMembers] = React.useState([]);
  const [blacklist, setBlacklist] = React.useState(null);
  const [whitelist, setWhitelist] = React.useState(null);
  const [coupons, setCoupons] = React.useState([]);
  const [expandedSections, setExpandedSections] = React.useState([]);

  React.useEffect(async () => {
    refreshBookingData();
    refreshActiveMembers();
    refreshCouponList();
    refreshDateList();
    setAllDates();
  }, []);

  const [allAvailableDates, setAllAvailableDates] = React.useState(null);

  const getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt).toLocaleDateString("en-US"));
    }
    return arr;
  };

  const setAllDates = () => {
    const currYear = new Date().getFullYear();
    var daylist = getDaysArray(
      new Date(`${currYear}-01-01`),
      new Date(`${currYear}-12-31`)
    );
    console.log("Setting available time dates: ", daylist);
    setAllAvailableDates(daylist);
  };

  const toggleVisited = async (objId, currVal) => {
    console.log(
      `Updating booking with id: ${objId} and curr value: ${currVal}`
    );
    const docRef = doc(db, "bookings", objId);

    await updateDoc(docRef, {
      visited: !currVal,
      lastUpdated: serverTimestamp(),
    });

    if (!done) {
      refreshBookingData();
      setDone(true);
    }
  };

  const refreshBookingData = async () => {
    const today = new Date().toLocaleDateString();

    console.log("Refreshing booking data");

    const querySnapshot = await getDocs(collectionGroup(db, "bookings"));

    const bookingsPriorFiltering = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    const upcomingBookings = bookingsPriorFiltering.filter(
      (booking) =>
        Object.keys(booking).length !== 0 &&
        moment(booking.date).isSameOrAfter(new Date().toLocaleDateString())
    );

    const upcomingBookingsFormatted = formatBookingData(
      upcomingBookings,
      bookingsPriorFiltering
    );

    console.log(
      `Got ${upcomingBookings.length} total bookings`,
      upcomingBookingsFormatted
    );

    setUpcomingBookings(upcomingBookingsFormatted);

    console.log("allBookingsFormatted", upcomingBookingsFormatted);

    console.log("Querying for bookings on: ", today);

    const todaysBookings = bookingsPriorFiltering.filter(
      (booking) => booking?.date == today
    );
    console.log(`Got ${upcomingBookings.length} bookings for today`);

    const todaysBookingsFormatted = formatBookingData(
      todaysBookings,
      bookingsPriorFiltering
    );

    setTodaysBookings(todaysBookingsFormatted);

    console.log("todaysBookingsFormatted", todaysBookingsFormatted);
  };

  const refreshActiveMembers = async () => {
    console.log("Querying for active members");

    const membersSnaption = await getDocs(collectionGroup(db, "activeMembers"));

    const activeMembers = membersSnaption.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    const activeMembersFormatted = formatMembersData(activeMembers);
    console.log("Active member: ", activeMembers);

    setActiveMembers(activeMembersFormatted);
  };

  const refreshCouponList = async () => {
    console.log("Querying for active coupons");

    const couponsSnapshot = await getDocs(collectionGroup(db, "coupons"));

    const visibleCoupons = couponsSnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    console.log("visibleCoupons: ", visibleCoupons);

    setCoupons(visibleCoupons);
  };

  const refreshDateList = async () => {
    console.log("Querying for whitelist/blacklist");

    const whitelistSnapshot = await getDocs(
      collectionGroup(db, "whitelistDates")
    );
    const blacklistSnapshot = await getDocs(
      collectionGroup(db, "blacklistDates")
    );

    const whitelist = formatDateData(
      whitelistSnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      }),
      "whitelistDates"
    );

    const blacklist = formatDateData(
      blacklistSnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      }),
      "blacklistDates"
    );

    console.log("whitelist: ", whitelist);
    console.log("blacklist: ", blacklist);

    setWhitelist(whitelist);
    setBlacklist(blacklist);
  };

  const formatMembersData = (data) => {
    const filteredData = data.map((obj) => {
      return [
        obj.email,
        <DeleteAdminButton
          deleteDocument={deleteDocument}
          confirmationPrompt="Are you sure you want to delete this membership?"
          coll="activeMembers"
          objId={obj.id}
        />,
      ];
    });
    return filteredData;
  };

  const formatDateData = (data, coll) => {
    const filteredData = data.map((obj) => {
      return [
        obj.date,
        <DeleteAdminButton
          deleteDocument={deleteDocument}
          confirmationPrompt={`Are you sure you want to remove ${obj.date}`}
          coll={coll}
          objId={obj.id}
        />,
      ];
    });
    return filteredData;
  };

  const formatBookingData = (data, bookingsPriorFiltering) => {
    // bookingsPriorFiltering helps count how often an email has been used for a booking
    const filteredData = data
      .sort((a, b) => {
        if (a.date === b.date) {
          console.log("same dates: ", a.date, b.date);
          return (
            new Date(a.date + " " + a.time) - new Date(b.date + " " + b.time)
          );
        } else {
          return new Date(a.date) - new Date(b.date);
        }
      })
      .map((obj) => {
        console.log("obj ", obj);
        return [
          obj.name,
          obj.date,
          obj.time,
          obj.people,
          obj.duration,
          obj.phoneNumber,
          obj.email,
          getVisitNumber(obj.email, bookingsPriorFiltering),
          // 0,
          <View className=" align-middle justify-center items-center text-center">
            <CheckBox
              value={"visited" in obj && obj.visited}
              onValueChange={() =>
                toggleVisited(obj.id, "visited" in obj && obj.visited)
              }
            />
          </View>,
          <TouchableOpacity
            className="w-full h-full bg-red-500 text-center"
            onPress={() => {
              var proceed = confirm(
                "Are you sure you want to delete this booking?"
              );
              if (proceed) {
                deleteDocument(obj.id, "bookings");
              }
            }}
          >
            <Text
              className="text-black items-center align-middle font-extrabold text-xl pt-3
           h-16"
            >
              x
            </Text>
          </TouchableOpacity>,
        ];
      });
    return filteredData;
  };

  const getVisitNumber = (email, bookingsPriorFiltering) => {
    console.log(
      "Getting visit number for: ",
      email,
      " bookingsPriorFiltering: ",
      bookingsPriorFiltering
    );
    var visitNumber = 0;
    bookingsPriorFiltering.map((obj) => {
      if (obj.email == email) {
        visitNumber++;
      }
    });
    return visitNumber;
  };

  const deleteDocument = async (id, collectionName) => {
    console.log("Deleting : ", id);
    await deleteDoc(doc(db, `${collectionName}`, id));
    refreshBookingData();
    refreshCouponList();
    refreshActiveMembers();
    refreshDateList();
  };

  // Function to toggle section visibility
  const toggleSection = (index) => {
    const updatedSections = [...expandedSections];
    if (updatedSections.includes(index)) {
      updatedSections.splice(updatedSections.indexOf(index), 1);
    } else {
      updatedSections.push(index);
    }
    setExpandedSections(updatedSections);
  };

  const isSectionExpanded = (index) => expandedSections.includes(index);

  const bookingHeader = [
    "Name",
    "Date",
    "Time",
    "People",
    "Duration",
    "Phone",
    "Email",
    "Visit #",
    "Visited?",
    "Delete?",
  ];

  const sections = [
    {
      title: "Today's Bookings",
      content: (
        <SaunaTable
          data={todaysBookings}
          header={bookingHeader}
          title="Today's Bookings:"
        />
      ),
    },
    {
      title: "All Upcoming Appointments",
      content: (
        <SaunaTable
          data={upcomingBookings}
          header={bookingHeader}
          title="All Upcoming Appointments:"
        />
      ),
    },
    {
      title: "Active Members",
      content: (
        <SaunaTable
          data={activeMembers}
          header={["Email", "Delete?"]}
          title="Active Members:"
        />
      ),
    },
    {
      title: "Coupons",
      content: (
        <CouponTable coupons={coupons} deleteDocument={deleteDocument} />
      ),
    },
    {
      title: "Whitelist/Blacklist",
      content: (
        <DateFieldAdminForm
          whitelist={whitelist}
          blacklist={blacklist}
          refreshDateList={refreshDateList}
          allAvailableDates={allAvailableDates}
        />
      ),
    },
    {
      title: "Bus Location",
      content: <LocationAdminForm />,
    },
  ];

  return (
    <Page
      navigation={navigation}
      body={
        <View style={{ flex: 1 }}>
          {sections.map((section, index) => (
            <List.Accordion
              key={index}
              title={section.title}
              expanded={isSectionExpanded(index)}
              onPress={() => toggleSection(index)}
              style={{
                width: "100%",
                backgroundColor: theme.orange,
                borderColor: "#000",
                borderWidth: "1px",
              }} // Ensure full width
              titleStyle={{ fontWeight: "bolder" }}
            >
              {section.content}
            </List.Accordion>
          ))}
        </View>
      }
    />
  );
}
