import * as React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import db from "../FireBase";
import { doc, setDoc } from "firebase/firestore";

const CouponTable = ({ coupons, deleteDocument }) => {
  const addPromoCode = async (code) => {
    await setDoc(doc(db, "coupons", code), {
      name: code,
    });

    refreshCouponList();
  };

  return (
    <View className="mx-4 p-2 border-8 border-[#fcefde] rounded-2xl my-7 w-90">
      <Text className="font-BobbyJones text-black mb-3 text-2xl">
        Coupons displayed on Events/Deals page:
        {"\n"}
      </Text>
      {coupons?.map((coupon) => (
        <View
          className="flex flex-row items-center text-center align-middle"
          id={coupon.id}
        >
          <Text className=" text-xl m-6">{coupon.name}</Text>
          <TouchableOpacity
            className=" w-52 h-16 bg-red-500 text-center"
            onPress={() => {
              var proceed = confirm(
                "Are you sure you want to delete this coupon from Events / Deals page?"
              );
              if (proceed) {
                deleteDocument(coupon.id, "coupons");
              }
            }}
          >
            <Text className="text-black items-center align-middle font-extrabold text-xl pt-3 h-16">
              x
            </Text>
          </TouchableOpacity>
        </View>
      ))}

      <TouchableOpacity
        className="h-16 bg-black text-center"
        onPress={() => {
          let code = prompt("Please enter promo to display");
          console.log("INPUT: ", code);
          if (code != null && code != "") {
            addPromoCode(code);
          }
        }}
      >
        <Text
          className="text-white items-center align-middle font-extrabold text-lg pt-3
             h-16"
        >
          Add a new coupon to Events & Deals Page{" "}
          <Text
            className="text-white items-center align-middle font-extrabold text-sm pt-3
             h-16"
          >
            (which exists in Stripe!)
          </Text>
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default CouponTable;
