import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { View, Text } from "react-native";
import { TouchableOpacity } from "react-native";
import db, { storage } from "../FireBase";
import { ref, getBytes, getDownloadURL } from "firebase/storage";
import Page from "../components/Page";
import { useFonts } from "expo-font";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { collectionGroup, getDocs } from "firebase/firestore";
import Toast from "react-native-toast-message";

import TextField from "../components/TextField";
import PhoneField from "../components/PhoneField";
import PickerField from "../components/PickerField";
import DateField from "../components/DateField";
import TimeField from "../components/TimeField";
import PaymentForm from "../components/PaymentForm";

const BUS_MAX_CAPACITY = 8;

export default function Booking({ navigation }) {
  const [fontsLoaded] = useFonts({
    BobbyJones: require("../assets/fonts/Bobby-Jones-Soft.otf"),
  });
  const phoneInputRef = useRef(null);
  const [name, setName] = useState("");
  const [blacklistedDates, setBlackListedDates] = useState(null);
  const [whitelistedDates, setWhiteListedDates] = useState(null);
  const [allAvailableTimeSlots, setAllAvailableTimeSlots] = useState(null);
  const [allAvailableDates, setAllAvailableDates] = useState(null);
  const [date, setDate] = useState(null);
  const [desiredPeople, setDesiredPeople] = useState(BUS_MAX_CAPACITY);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [availableOptions, setAvailableOptions] = useState(undefined);
  const [startTime, setStartTime] = useState();
  const [membershipValidated, setMembershipValidated] = useState(false);
  const [isValidMember, setIsValidMember] = useState(false);
  const [confirmedDetails, setConfirmedDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(
      "name: ",
      name,
      "email: ",
      email,
      "phone: ",
      phoneNumber,
      "date: ",
      date?.toLocaleDateString(),
      "desiredPeople: ",
      desiredPeople,
      "start-time: ",
      startTime
    );
  }, [name, email, phoneNumber, date, desiredPeople, startTime]);

  useEffect(() => {
    // TODO: Currently this happens for any field change
    // only do this for date, people change
    if (name && email && phoneNumber && date && desiredPeople) {
      // all fields available, and change detected in date or # of people
      // setLoading(true);
      refreshTimeSlots();
      // setLoading(false);
    }
  }, [date, desiredPeople]);

  useEffect(() => {
    getBlacklistWhitelistDates();
    getAvailableTimeSlotsFromDb();
  }, []);

  const getBlacklistWhitelistDates = async () => {
    const querySnapshot1 = await getDocs(collectionGroup(db, "blacklistDates"));
    const querySnapshot2 = await getDocs(collectionGroup(db, "whitelistDates"));

    const blacklistDates = querySnapshot1.docs.map((doc) => doc.data().date);
    const whitelistDates = querySnapshot2.docs.map((doc) => doc.data().date);

    console.log("blacklistDates : ", blacklistDates);
    console.log("whitelistDates : ", whitelistDates);

    setWhiteListedDates(whitelistDates);
    setBlackListedDates(blacklistDates);
  };

  const getDayOfWeek = (dateString) => {
    const dayNames = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const date = new Date(dateString);
    return dayNames[date.getDay()];
  };

  // Function to format date "MM/DD/YYYY" without leading zeros
  const formatDate = (date) => {
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  };

  const getAvailableTimeSlotsFromDb = async () => {
    console.log("Fetching all available time slots...");
    const csvtojson = require("csvtojson");

    const isLocal = window.location.href.includes("localhost");

    // ignore next 7k lines due to local testing
    // if (isLocal) {
    //   const currentYear = new Date().getFullYear();
    //   const firstDayOfYear = new Date(currentYear, 0, 1);
    //   let weekendDates = [];
    //   for (
    //     let day = firstDayOfYear;
    //     day.getFullYear() === currentYear;
    //     day.setDate(day.getDate() + 1)
    //   ) {
    //     if (day.getDay() === 0 || day.getDay() === 5 || day.getDay() === 6) {
    //       // Sunday or Saturday
    //       weekendDates.push(formatDate(day));
    //     }
    //   }
    //   const times = [
    //     "8:00 AM",
    //     "9:30 AM",
    //     "11:00 AM",
    //     "12:30 PM",
    //     "2:00 PM",
    //     "3:30 PM",
    //     "5:00 PM",
    //     "6:30 PM",
    //     "8:00 PM",
    //     "9:30 PM",
    //   ];

    //   const schedule = weekendDates.flatMap((date) => {
    //     return times.map((time) => {
    //       return {
    //         date: date,
    //         day: getDayOfWeek(date),
    //         time: time,
    //         people: 8,
    //         duration: "60",
    //       };
    //     });
    //   });
    //   console.log("setting available time slots: ", schedule);
    //   console.log("setting available dates", weekendDates);
    //   setAllAvailableTimeSlots(schedule);
    //   setAllAvailableDates(weekendDates);
    // } else {
    const isProd = window.location.href.includes("saunasocial.co");

    const host = isProd ? "sauna-social" : "sauna-social-dev";

    const fileUrl = `gs://${host}.appspot.com/time_slots.csv`;

    console.log("Getting time_slots.csv: ", fileUrl);
    const fileRef = ref(storage, fileUrl);

    const url = await getDownloadURL(fileRef);
    console.log("Calling: ", url);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // or response.blob() if the file is binary
      })
      .then((text) => {
        console.log("Fetched text: ", text);
      })
      .catch((error) => {
        console.error("Error fetching file: ", error);
      });

    console.log("fileRef: ", fileRef);

    const res = await getBytes(fileRef);
    console.log("res: ", res);

    const enc = new TextDecoder("utf-8");
    console.log("enc: ", enc);
    const csvStr = enc.decode(res);
    console.log("CSV string: ", csvStr);
    // Converting people and duration to numbers
    const csvAsJson = await csvtojson().fromString(csvStr);
    csvAsJson.forEach(
      (o, i, a) =>
        (a[i] = {
          ...o,
          people: parseInt(o["people"]),
        })
    );
    console.log("csvAsJson: ", csvAsJson);

    setAllAvailableTimeSlots(csvAsJson);
    const allAvailableDates = csvAsJson?.map((details) => details.date);
    // console.log("All available dates: ", allAvailableDates);

    console.log("allAvailableDates: ", csvAsJson);

    setAllAvailableDates(allAvailableDates);
    // }
  };

  const refreshTimeSlots = async () => {
    console.log("refreshing time slots...");

    // dateValidation();

    const dateToQuery = date.toLocaleDateString();

    // console.log("Querying for bookings on: ", dateToQuery);

    const querySnapshot = await getDocs(collectionGroup(db, "bookings"));

    const bookingsPriorFiltering = querySnapshot.docs.map((doc) => doc.data());

    const existingBookings = bookingsPriorFiltering.filter(
      (booking) =>
        Object.keys(booking).length !== 0 && booking.date == dateToQuery
    );

    // Converting people and duration to numbers
    existingBookings.forEach(
      (o, i, a) =>
        (a[i] = {
          ...o,
          people: parseInt(o["people"]),
        })
    );

    // console.log("existingBookings: ", existingBookings);

    // console.log("All available time slots: ", allAvailableTimeSlots);

    // Filtering for selected day
    // let days = [
    //   "Sunday",
    //   "Monday",
    //   "Tuesday",
    //   "Wednesday",
    //   "Thursday",
    //   "Friday",
    //   "Saturday",
    // ];

    // const selectedDay = days[date.getDay()].toLowerCase();

    console.log("Filtering for date: ", dateToQuery);

    const slotsCopy = allAvailableTimeSlots.map((a) => {
      return { ...a };
    });

    const slotsForSelectedDay = slotsCopy.filter(
      (slotDetails) => slotDetails.date == dateToQuery
    );
    console.log("Slots matching ", dateToQuery, " - ", slotsForSelectedDay);

    if (existingBookings.length == 0) {
      // No existing bookings for this day
      // Returning all available times
      console.log("No bookings for this day, setting: ", slotsForSelectedDay);
      setAvailableOptions(slotsForSelectedDay);
      const firstSlot = slotsForSelectedDay[0];
      setStartTime(
        `${firstSlot.time} - ${firstSlot.duration} mins (${firstSlot.people} spots left)`
      );
      return;
    }

    // Removing booked people
    const slotsMinusBookings = slotsForSelectedDay.map((slot) => {
      return {
        ...slot,
        people: existingBookings.find((booking) => booking.time == slot["time"])
          ? slot.people -
            existingBookings
              .filter((booking) => booking.time == slot["time"])
              .reduce((accumulator, booking) => {
                return accumulator + booking.people;
              }, 0)
          : slot.people,
      };
    });

    // Removing desired people
    const finalSlots = slotsMinusBookings.filter(
      (obj) => obj["people"] - desiredPeople >= 0
    );

    console.log("finalSlots: ", finalSlots);

    if (finalSlots.length > 0) {
      setAvailableOptions(finalSlots);
      console.log("available options und: ", availableOptions);
      const firstSlot = finalSlots[0];
      setStartTime(
        `${firstSlot.time} - ${firstSlot.duration} mins (${firstSlot.people} spots left)`
      );
    } else {
      setAvailableOptions(undefined);
    }
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const validateFields = () => {
    // Add any other fields you consider mandatory
    if (!isValidEmail(email)) {
      Toast.show({
        type: "error",
        text1: "Invalid email",
        text2: "Please ensure the provided email is valid.",
      });
      return false;
    }
    if (!name || !phone || !reason) {
      Toast.show({
        type: "error",
        text1: "Missing Information",
        text2: "Please fill in all mandatory fields.",
      });
      return false;
    }
    return true;
  };

  function dateValidation() {
    const selecteDay = date
      .toLocaleDateString("en-US", { weekday: "long" })
      .toLowerCase();

    if (!["friday", "saturday", "sunday"].includes(selecteDay)) {
      throw new Error("Unsupported day selected: ", selecteDay);
    }
    return selecteDay;
  }

  const validateMembership = async () => {
    // Query Firebase for memberships matching email
    console.log(`Checking if ${email} is a member...`);

    const querySnapshot = await getDocs(collectionGroup(db, "activeMembers"));

    var membersPriorFiltering = querySnapshot.docs.map((doc) => doc.data());
    var activeMemberRecord = membersPriorFiltering.filter((record) => {
      return Object.keys(record).length !== 0;
    });

    activeMemberRecord = activeMemberRecord.filter((record) => {
      // console.log("active member: ", record);
      return (
        "email" in record &&
        record["email"].toLowerCase() == email.toLowerCase()
      );
    });

    console.log("Any matching membership: ", activeMemberRecord);

    setMembershipValidated(true);

    if (activeMemberRecord.length == 0) {
      console.log(
        `No active member found matching ${email}, FB response: ${activeMemberRecord}`
      );
      setIsValidMember(false);
    } else {
      console.log(
        `Active member found matching ${email}, FB response: ${activeMemberRecord}`
      );
      setIsValidMember(true);
    }
  };

  return (
    <Page
      navigation={navigation}
      loading={loading}
      body={
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <View className="w-full flex flex-1 flex-col items-center text-center justify-center">
            <Text className=" font-bold font-BobbyJones text-3xl mb-6 mt-2">
              {!confirmedDetails
                ? "Booking"
                : isValidMember
                ? `Welcome Back ${name.split(" ")[0]}!`
                : "Payment Options"}
            </Text>

            <Stack spacing={2}>
              {!confirmedDetails && (
                <View className="p-4 bg-orange flex flex-col max-w-lg  items-end ">
                  <TouchableOpacity
                    onPress={() => navigation.navigate("Membership")}
                    style={{
                      borderColor: "#fcefde",
                      borderWidth: 1,
                      height: "30px",
                      justifyContent: "center",
                    }} // Inline style for custom border
                    className="p-2" // Tailwind classes for other stylings
                  >
                    <Text className="text-white text-center font-bold font-BobbyJones">
                      Want to become a member?
                    </Text>
                  </TouchableOpacity>

                  <TextField
                    title="Name:"
                    placeholder="xxxxxxxxxx"
                    value={name}
                    setValue={setName}
                  />

                  <TextField
                    title="Email:"
                    placeholder="xxxxxxxxxx"
                    value={email}
                    setValue={setEmail}
                  />

                  <PhoneField
                    reference={phoneInputRef}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                  />

                  <PickerField
                    title="People:"
                    value={desiredPeople}
                    setValue={setDesiredPeople}
                    options={Array.from(
                      { length: BUS_MAX_CAPACITY },
                      (_, k) => k + 1
                    )}
                  />

                  {allAvailableDates && (
                    <DateField
                      date={date}
                      setDate={setDate}
                      blacklistedDates={blacklistedDates}
                      whitelistedDates={whitelistedDates}
                      allAvailableDates={allAvailableDates}
                    />
                  )}

                  <TimeField
                    name={name}
                    phoneNumber={phoneNumber}
                    date={date}
                    desiredPeople={desiredPeople}
                    availableOptions={availableOptions}
                    startTime={startTime}
                    setStartTime={setStartTime}
                  />
                </View>
              )}

              {confirmedDetails && !membershipValidated && (
                <Text className="font-BobbyJones text-2xl">
                  {" "}
                  Checking Membership Status ...
                </Text>
              )}

              {confirmedDetails && membershipValidated && (
                <PaymentForm
                  navigation={navigation}
                  name={name}
                  email={email}
                  phoneNumber={phoneNumber}
                  date={date}
                  isValidMember={isValidMember}
                  desiredPeople={desiredPeople}
                  startTime={startTime?.split("-")[0].trim()}
                  duration={parseInt(
                    startTime?.split("-")[1].trim().split(" ")[0]
                  )}
                  setLoading={setLoading}
                  confirmedDetails={confirmedDetails}
                  setConfirmedDetails={setConfirmedDetails}
                />
              )}

              {name &&
                email &&
                phoneNumber &&
                date &&
                desiredPeople &&
                availableOptions &&
                !confirmedDetails && (
                  <TouchableOpacity
                    onPress={() => {
                      // Phone number and email validation
                      if (phoneNumber.length < 10) {
                        Toast.show({
                          type: "error",
                          text1: `Phone # Is not valid: ${phoneNumber}`,
                          text2: "Please include area code",
                        });
                      } else if (!isValidEmail(email)) {
                        Toast.show({
                          type: "error",
                          text1: "Invalid email",
                          text2: "Please ensure the provided email is valid.",
                        });
                      } else {
                        setConfirmedDetails(true);
                        validateMembership();
                      }
                    }}
                    className="w-full h-16 bg-black justify-center align-middle text-center mt-4 items-center"
                  >
                    <Text className="text-white font-BobbyJones text-2xl">
                      Proceed
                    </Text>
                  </TouchableOpacity>
                )}
            </Stack>

            {!confirmedDetails && (
              <TouchableOpacity
                onPress={() => navigation.navigate("ContactUs")}
              >
                <Text className="font-BobbyJones text-sm mt-24">
                  Hosting a special event? Contact Us!
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </LocalizationProvider>
      }
    />
  );
}
