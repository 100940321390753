import * as React from "react";
import { View, Text, TextInput } from "react-native";
import { useState, useRef } from "react";
import Stack from "@mui/material/Stack";
import Page from "../components/Page";
import TextField from "../components/TextField";
import PhoneField from "../components/PhoneField";
import PickerField from "../components/PickerField";
import { TouchableOpacity } from "react-native-web";
import Footer from "../components/Footer";
import emailjs from "@emailjs/browser";
import Toast from "react-native-toast-message";

export default function ComingSoon({ navigation }) {
  const phoneInputRef = useRef(null);

  const [name, setName] = useState("");
  const [busName, setBusName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("Group Booking");
  const [message, setMessage] = useState("");
  const [proceed, setProceed] = useState(false);
  const [msgSent, setMsgSent] = useState(false);

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const validateFields = () => {
    // Add any other fields you consider mandatory
    if (!isValidEmail(email)) {
      Toast.show({
        type: "error",
        text1: "Invalid email",
        text2: "Please ensure the provided email is valid.",
      });
      return false;
    }
    if (!name || !phone || !reason) {
      Toast.show({
        type: "error",
        text1: "Missing Information",
        text2: "Please fill in all mandatory fields.",
      });
      return false;
    }
    return true;
  };

  const proceedFirstSection = () => {
    if (!validateFields()) {
      return;
    }
    setProceed(true);
  };

  const sendMessage = () => {
    if (!message) {
      Toast.show({
        type: "error",
        text1: "Blank input",
        text2: "You didn't say anything :(",
      });
      return false;
    }

    console.log("Sending contact email");
    emailjs.send(
      "service_80nv3nh",
      "template_bmrmv1b",
      {
        name: name,
        busName: busName,
        phone: phone,
        email: email,
        reason: reason,
        message: message,
      },
      "j0sr38ITlWYjkQ7Z7"
    );
    console.log("Sent");

    setMsgSent(true);
  };

  return (
    <Page
      navigation={navigation}
      body={
        <View className="flex flex-1 flex-col mt-4 items-center text-center justify-center">
          <Text className=" font-bold font-BobbyJones text-3xl mb-2">
            Contact Us
          </Text>
          {msgSent ? (
            <View className="flex justify-center items-center mt-32">
              <Text className="font-BobbyJones text-xl">
                Thank you for contacting Sauna Social.
              </Text>

              <Text className="font-BobbyJones text-xl">
                We will be getting in touch with you shortly.
              </Text>

              <TouchableOpacity
                className="h-16 w-96 justify-center bg-black items-center mt-10"
                onPress={() => navigation.navigate("Home")}
              >
                <Text className=" text-white font-BobbyJones text-xl">
                  Home
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View className="items-center top-4">
              <Stack spacing={4}>
                <View className="p-4 bg-orange max-w-lg items-center">
                  {!proceed && (
                    <View className="items-end">
                      <TextField
                        title="Name"
                        placeholder="xxxxxxxxxx"
                        value={name}
                        setValue={setName}
                      />
                      <TextField
                        title="Business:"
                        placeholder="(Optional)"
                        value={busName}
                        setValue={setBusName}
                        textSize="text-xl"
                      />

                      <PhoneField
                        reference={phoneInputRef}
                        value={phone}
                        setValue={setPhone}
                      />

                      <TextField
                        title="Email:"
                        placeholder="go_pacers@gmail.com"
                        value={email}
                        setValue={setEmail}
                      />

                      <PickerField
                        title="Reason:"
                        value={reason}
                        setValue={setReason}
                        options={[
                          "Group Booking",
                          "Corporate Event",
                          "Billing/Membership",
                          "Collaboration/Partnership",
                          "Other",
                        ]}
                      />
                    </View>
                  )}

                  {/* Message */}
                  {proceed && (
                    <TextInput
                      placeholder="Message..."
                      multiline={true}
                      onChangeText={setMessage}
                      value={message}
                      keyboardType="default"
                      placeholderTextColor="#757575"
                      underlineColorAndroid="transparent" // Add this line for Android
                      style={{ outline: "none" }} // Add this line
                      className=" w-96 h-56 p-4 font-BobbyJones mt-2 bg-[#fcefde] outline-none text-lg"
                    />
                  )}
                </View>
              </Stack>

              {/* Button */}
              {!proceed ? (
                <View className="items-center">
                  <TouchableOpacity
                    className="h-16 w-96 justify-center bg-black items-center m-1"
                    onPress={proceedFirstSection}
                  >
                    <Text className=" text-white font-BobbyJones text-xl">
                      Continue
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View className="flex-row m-1 justify-between">
                  <TouchableOpacity
                    className="h-16 w-48 justify-center bg-black items-center mr-1"
                    onPress={() => setProceed(false)}
                  >
                    <Text className=" text-white font-BobbyJones text-xl">
                      Go Back
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="h-16 w-48 justify-center bg-black items-center ml-1"
                    onPress={() => sendMessage()}
                  >
                    <Text className=" text-white font-BobbyJones text-xl">
                      Continue
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          )}

          <Footer />
        </View>
      }
    />
  );
}
