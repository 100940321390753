import * as React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import Booking from "./screens/Booking";
import Home from "./screens/Home";
import { useState, useEffect } from "react";
import BookingConfirmation from "./screens/BookingConfirmation";
import OurStory from "./screens/OurStory";
import { TailwindProvider } from "tailwindcss-react-native";
import ComingSoon from "./screens/ComingSoon";
import ContactUs from "./screens/ContactUs";
import GiftVouchers from "./screens/GiftVouchers";
import Membership from "./screens/Membership";
import Pricing from "./screens/Pricing";
import EventsAndDeals from "./screens/EventsAndDeals";
import Admin from "./screens/Admin";
import Location from "./screens/Location";
import FAQ from "./screens/FAQ";
import Store from "./screens/Store";
import * as Linking from "expo-linking";
import Toast from "react-native-toast-message";

const Drawer = createDrawerNavigator();

export default function App({ navigation }) {
  const [nav, setNav] = useState(navigation);

  useEffect(() => setNav(navigation), []);

  const screenOptions = {
    drawerType: "front",
    drawerStyle: {
      backgroundColor: "#000",
      width: 200,
      fontFamily: "BobbyJones",
    },
    drawerContentStyle: {
      color: "#c4e6c7",
    },
    drawerLabelStyle: {
      color: "#fff",
      fontFamily: "BobbyJones",
    },
    headerShown: false,
    drawerPosition: "right",
    drawerActiveTintColor: "white",
  };

  const config = {
    screens: {
      Home: "Home",
      OurStory: "OurStory",
      Booking: "Booking",
      BookingConfirmation: "BookingConfirmation",
      ContactUs: "ContactUs",
      Pricing: "Pricing",
      EventsAndDeals: "EventsAndDeals",
      Store: "Store",
      Location: "Location",
      Admin: "Admin",
      FAQ: "FAQ",
      Membership: "Membership",
    },
  };

  const linking = {
    prefixes: [Linking.createURL("/"), "https://saunasocial.co"],
    config,
  };

  return (
    <TailwindProvider>
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <Drawer.Navigator
          useLegacyImplementation
          initialRouteName="Home"
          screenOptions={screenOptions}
        >
          <Drawer.Screen name="Home" component={Home} navigation={navigation} />
          <Drawer.Screen
            name="OurStory"
            component={OurStory}
            navigation={navigation}
            options={{
              title: "Our Story",
            }}
          />
          <Drawer.Screen
            name="Booking"
            component={Booking}
            navigation={navigation}
          />
          <Drawer.Screen
            name="Pricing"
            component={Pricing}
            navigation={navigation}
          />
          <Drawer.Screen name="FAQ" component={FAQ} navigation={navigation} />
          <Drawer.Screen
            name="EventsAndDeals"
            component={EventsAndDeals}
            navigation={navigation}
            options={{
              title: "Events and Deals",
            }}
          />
          {/* <Drawer.Screen
            name="Gallery"
            component={Gallery}
            navigation={navigation}
          /> */}
          <Drawer.Screen
            name="Store"
            component={Store}
            navigation={navigation}
          />
          <Drawer.Screen
            name="Location"
            component={Location}
            navigation={navigation}
          />

          <Drawer.Screen
            name="GiftVouchers"
            component={GiftVouchers}
            navigation={navigation}
            options={{
              title: "Gift Vouchers",
            }}
          />
          <Drawer.Screen
            name="Membership"
            component={Membership}
            navigation={navigation}
            options={{
              title: "Membership",
            }}
          />

          <Drawer.Screen
            name="ContactUs"
            component={ContactUs}
            navigation={navigation}
            options={{
              title: "Contact Us",
            }}
          />

          {/* Not displaying on the Drawer */}
          <Drawer.Screen
            name="BookingConfirmation"
            component={BookingConfirmation}
            navigation={navigation}
            options={{
              drawerItemStyle: {
                display: "none",
              },
            }}
          />
          <Drawer.Screen
            name="ComingSoon"
            component={ComingSoon}
            navigation={navigation}
            options={{
              drawerItemStyle: {
                display: "none",
              },
            }}
          />

          <Drawer.Screen
            name="Admin"
            component={Admin}
            navigation={navigation}
            options={{
              drawerItemStyle: {
                display: "none",
              },
            }}
          />
        </Drawer.Navigator>
      </NavigationContainer>
      <Toast ref={(ref) => Toast.setRef(ref)} />
    </TailwindProvider>
  );
}
