import * as React from "react";
import { View, Text } from "react-native";
import Page from "../components/Page";

export default function OurStory({ navigation }) {
  return (
    <Page
      navigation={navigation}
      body={
        <View className="items-center ">
          <View className="mx-4 p-2 border-8 border-[#fcefde] rounded-2xl mb-7 max-w-3xl bg-orange">
            <Text className="font-BobbyJones text-black mb-3 text-2xl underline">
              Our Story{"\n"}
            </Text>
            <Text className="font-BobbyJones text-lg">
              Three friends started Sauna Social for three different reasons.
              {"\n"}
              {"\n"}1. Obsession with schoolie conversions.
              {"\n"}2. Infatuation with wood burning saunas.
              {"\n"}3. Our search for eco-friendly "me-time" alternative to +10
              minute hot showers.
              {"\n"}
              {"\n"}Our school bus, unofficially called "Schooleesha," became
              ours in the summer of 2021. It took us over a year of careful
              deliberation on sustainable material, green energy options, and
              thoughtful design choices, before we opened our doors to our first
              customer.
              {"\n"}
              {"\n"}Our mission? We want to introduce our Indiana neighbors to
              the mental and physical benefits of regular sauna visits.
              Hydration, deep breathing, thermal therapy, or the
              under-appreciated value of "quiet" are just few of the many
              benefits that can lead to new positive changes in your health and
              wellbeing.
              {"\n"}
              {"\n"}We're big on access to wellness in all communities - hence
              the wheels! We hope to bring this unique detoxifying experience to
              anyone, anywhere. Everyone is welcome aboard Sauna Social. We are
              passionate about maintaining the integrity of the sauna culture
              and strive to create an atmosphere of peace for our guests.
            </Text>
          </View>
        </View>
      }
    />
  );
}
