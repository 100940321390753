import { View, ScrollView } from "react-native";
import React from "react";
import Header from "./Header";
import { useFonts } from "expo-font";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import LoadingOverlay from "@ronchalant/react-loading-overlay";

const Page = ({ navigation, body, loading, confetti }) => {
  const { width, height } = useWindowSize();
  const [fontsLoaded] = useFonts({
    BobbyJones: require("../assets/fonts/Bobby-Jones-Soft.otf"),
  });
  return loading ? (
    <View className="bg-black z-50 m-auto w-full h-full justify-center">
      <LoadingOverlay active spinner text="Transferring to checkout..." />
    </View>
  ) : (
    <>
      <ScrollView className="flex align-middle bg-green mb-auto w-full h-full">
        <Header navigation={navigation} />
        {confetti && <Confetti width={width} height={height} />}

        <View className="pb-8 flex-1">{body}</View>
      </ScrollView>
    </>
  );
};

Page.defaultProps = {
  loading: false,
  confetti: false,
};

export default Page;
