import * as React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  TextInput,
} from "react-native";
import Page from "../components/Page";
import Toast from "react-native-toast-message";

export default function Membership({ navigation, route }) {
  const { email = "" } = route.params || {};
  const [userEmail, setUserEmail] = React.useState(email);
  const [loading, setLoading] = React.useState(false);

  const screenWidth = Dimensions.get("window").width;
  const isSmallScreen = screenWidth < 768;

  const isLocal = !window.location.href.includes("saunasocial.co");

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const createMembership = async () => {
    // Validate email before proceeding
    if (!isValidEmail(userEmail)) {
      Toast.show({
        type: "error",
        text1: "Invalid Email",
        text2: "Please enter a valid email address.",
      });
      return; // Stop the function if the email is not valid
    }

    const paymentId = isLocal
      ? "price_1OV2JkEEFNmAFqxh82jEPiyc"
      : "price_1O18EeEEFNmAFqxhGSkAoSF7";

    var raw = JSON.stringify({
      paymentId: paymentId,
      email: userEmail,
      newMember: true,
      clientHost: window.location.hostname,
    });

    console.log("Request Body: ", raw);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const funcUrl = isLocal
      ? "https://sshandlebookingdev.azurewebsites.net/api/sshandlebookingdev"
      : `https://sshandlebooking.azurewebsites.net/api/sshandlebooking`;

    setLoading(true);

    fetch(funcUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("Payment link creation response: ", result);
        const url = JSON.parse(result)["url"];
        window.location.href = url; // Proceeding to Stripe
      })
      .catch((error) => {
        alert(
          `Uh oh... something went wrong. Please try again or contact support. Error: ${
            error.message || error
          }`
        );
      });
  };

  return (
    <Page
      navigation={navigation}
      loading={loading}
      body={
        <View className="flex items-center mt-4 w-full justify-center">
          <Text className="font-bold font-BobbyJones text-3xl mb-2 text-black">
            Membership
          </Text>
          <View
            className={`bg-orange border-8 border-[#fcefde] rounded-2xl ${
              isSmallScreen ? "w-4/5" : "w-1/2"
            } h-6/7 p-4 rounded-md items-center mt-5`}
          >
            <View className="w-full p-2 rounded-md items-center">
              <Text className=" text-black font-BobbyJones text-sm text-center">
                <Text>Unlimited sessions (booking still required){"\n"}</Text>
                <Text>10% discount on group booking{"\n"}</Text>
                <Text>One free guest pass per month{"\n"}</Text>
                <Text className=" text-black font-BobbyJones text-lg ml-2 mr-2 underline">
                  $70 monthly
                </Text>
              </Text>
            </View>

            <View className="flex-col items-start mt-3 justify-evenly">
              <Text className={`font-BobbyJones text-[#fcefde] text-2xl`}>
                Email:
              </Text>
              <TextInput
                onChangeText={setUserEmail}
                value={userEmail}
                placeholder="xxxxxxxxxx"
                keyboardType="default"
                placeholderTextColor="#757575"
                className="w-72 h-8 p-4 pl-4 font-BobbyJones"
                style={{
                  backgroundColor: "#fcefde",
                  outline: "none",
                  fontSize: "18px",
                }}
              />
            </View>

            <TouchableOpacity
              className="flex h-16 w-3/4 justify-center m-2 p-4 bg-black mt-10"
              onPress={createMembership}
            >
              <View className="flex-1 justify-center items-center">
                <Text className="text-white font-BobbyJones text-xl">
                  Become a Member
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      }
    />
  );
}
