import * as React from "react";
import { View, Text } from "react-native";
import Page from "../components/Page";
import CouponDetails from "../components/CouponDetails";
import EventDetails from "../components/EventDetails";
import db from "../FireBase";
import { collectionGroup, getDocs } from "firebase/firestore";
import { ref, getBytes } from "firebase/storage";
import { storage } from "../FireBase";
import { concat } from "react-native-reanimated";

export default function EventsAndDeals({ navigation }) {
  const [coupons, setCoupons] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const isLocal = !window.location.href.includes("saunasocial.co");
  const [events, setEvents] = React.useState([]);

  const getEvents = async () => {
    const csvtojson = require("csvtojson");

    const env = isLocal ? "sauna-social-dev" : "sauna-social";

    const fileRef = ref(storage, `gs://${env}.appspot.com/events.csv`);

    const res = await getBytes(fileRef);
    const enc = new TextDecoder("utf-8");
    const csvStr = enc.decode(res);

    var csvAsJson;
    await csvtojson()
      .fromString(csvStr)
      .then((val) => {
        csvAsJson = val;
      });

    console.log("Events Data: ", csvAsJson);

    setEvents(csvAsJson);
    // setEvents([
    //   {
    //     day: "Jan 6",
    //     event: "First Friday: North Mass Boulder",
    //     time: "6 pm",
    //   },
    //   {
    //     day: "Feb 6",
    //     event: "First Friday: TBD",
    //     time: "6 pm",
    //   },
    //   {
    //     day: "Every Sunday",
    //     event: "Guided 60-Min Sauna: Quite Time",
    //     time: "12 pm",
    //   },
    // ]);
  };

  const getCoupons = async () => {
    try {
      console.log("Getting Coupons ");

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };
      const funcUrl = isLocal
        ? "https://sshandlebookingdev.azurewebsites.net/api/sscouponsdev?code=gVkc_mH1IYibLU5KfffoA4Q3SR-xVPxHfLdzx17LSb12AzFutPpyRw=="
        : `https://sshandlebooking.azurewebsites.net/api/sscoupons?code=fjNjRfE5YdO2-YtEpuaT5z09fIOVI0NVLqg4u_qnlMN9AzFu_UKPtA==`;

      fetch(funcUrl, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("Payment link creation response: ", result);
          const parsedResults = JSON.parse(result);
          console.log("Parsed: ", parsedResults);
          const data = parsedResults["data"].map((coupon) => {
            return { name: coupon.name, percent_off: coupon.percent_off };
          });
          console.log("Data: ", data);

          if (Array.isArray(data) && data.length > 0) {
            // Checking which coupons should be displayed -
            // this list is stored in DB and can be updated via Admin page

            const couponsSnapshot = await getDocs(
              collectionGroup(db, "coupons")
            );
            console.log("couponsSnapshot: ", couponsSnapshot);

            const visibleCoupons = couponsSnapshot.docs.map(
              (doc) => doc.data().name
            );
            console.log("Coupons we should display: ", visibleCoupons);
            const filteredCoupons = data.filter((coupon) =>
              visibleCoupons.includes(coupon.name)
            );
            console.log("filteredCoupons", filteredCoupons);
            if (filteredCoupons.length > 0) {
              setCoupons(filteredCoupons);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          alert(
            "Uh oh... something went wrong. Please try again or contact support. Error: ",
            error
          );
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log("ERROR: ", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getCoupons();
    getEvents();
  }, []);

  return (
    <Page
      navigation={navigation}
      body={
        <View className="flex items-center mt-4 w-full">
          <Text className=" font-bold font-BobbyJones text-3xl mb-2 text-black">
            Events/Deals
          </Text>
          <View className="w-full items-center">
            {!coupons && (
              <Text className="font-BobbyJones text-2xl mt-4">
                {loading ? "Fetching deals..." : "No deals available :("}
              </Text>
            )}
            {events &&
              events?.map((e) => {
                return <EventDetails event={e} />;
              })}
            {coupons &&
              coupons?.map((e) => {
                return <CouponDetails coupon={e} />;
              })}
          </View>
        </View>
      }
    />
  );
}
