import { View, Text } from "react-native";
import { MobileDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";

import React from "react";

const DateField = ({
  date,
  setDate,
  blacklistedDates,
  whitelistedDates,
  allAvailableDates,
}) => {
  return (
    <View className="flex-row items-center justify-between mt-3">
      <Text className="text-2xl font-BobbyJones mr-2 text-[#fcefde]">
        Date:
      </Text>
      <View className="bg-[#fcefde] w-72 cursor-pointer">
        <MobileDatePicker
          // label="Date"
          minDate={new Date()} // Disables all dates in the past
          value={date}
          onChange={setDate}
          renderInput={(params) => <TextField {...params} />}
          variant="standard"
          closeOnSelect={true}
          shouldDisableDate={(date) => {
            const currDate = date?.toLocaleDateString("en-US");
            return (
              (!whitelistedDates?.includes(currDate) &&
                blacklistedDates?.includes(currDate)) ||
              !allAvailableDates.includes(currDate)
            );
          }}
        />
      </View>
    </View>
  );
};

DateField.defaultProps = {
  date: new Date(),
  blacklistedDates: [],
  whitelistedDates: [],
  // whitelistWeekendsOnly: true,
};
export default DateField;
