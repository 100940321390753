import * as React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Page from "../components/Page";

export default function Store({ navigation }) {
  return (
    <Page
      navigation={navigation}
      body={
        <View className="w-full flex flex-1 flex-col items-center text-center justify-center">
          <Text className=" font-bold font-BobbyJones text-3xl mb-4 mt-4">
            Store{"\n"}
          </Text>

          {/* <View className=" w-2/3 max-w-lg bg-orange text-center items-center pl-8 pr-8 pt-4 pb-4 rounded-xl flex flex-col">
            <Text className="text-lg font-BobbyJones">
              Send your family and friends a gift of wellness
            </Text>

            <TouchableOpacity
              className="h-14 w-2/3 justify-center bg-white items-center mt-2 rounded-xl"
              onPress={() => {
                window.location =
                  "https://squareup.com/gift/ML1M7KNK3AHRF/order";
              }}
            >
              <Text className=" text-black font-BobbyJones text-2xl">
                Gift Card
              </Text>
            </TouchableOpacity>
          </View> */}

          <Text className="font-BobbyJones text-sm mt-8">
            Merch Coming Soon ...
          </Text>
        </View>
      }
    />
  );
}
