import * as React from "react";
import { View, Text } from "react-native";
import Page from "../components/Page";

export default function ComingSoon({ navigation }) {
  return (
    <Page
      navigation={navigation}
      body={
        <View className="flex justify-between items-center mt-32 ">
          <Text className="font-BobbyJones text-2xl"> Coming Soon ...</Text>
        </View>
      }
    />
  );
}
