import { View, Text } from "react-native";
import { Picker } from "@react-native-picker/picker";
import React from "react";

const TimeField = ({
  name,
  phoneNumber,
  date,
  desiredPeople,
  availableOptions,
  startTime,
  setStartTime,
}) => {
  return name &&
    phoneNumber &&
    date &&
    desiredPeople &&
    availableOptions !== undefined &&
    availableOptions.length != 0 ? (
    <View className="flex-row items-center justify-between mt-3">
      <Text className="text-2xl font-BobbyJones mr-2 text-[#fcefde]">
        Time:
      </Text>
      <Picker
        selectedValue={
          startTime === null ? availableOptions[0].time : startTime
        }
        onValueChange={(val) => setStartTime(val)}
        style={{
          width: "18rem",
          height: "2rem",
          paddingLeft: "12px",
          backgroundColor: "#fcefde",
          color: "black",
          outline: "none",
          fontSize: "18px",
          border: "none",
          // fontWeight: "bold",
        }}
      >
        {availableOptions.map((x) => {
          const val = `${x.time} - ${x.duration} mins (${x.people} spots left)`;
          return <Picker.Item key={val} label={val} value={val} />;
        })}
      </Picker>
    </View>
  ) : name &&
    phoneNumber &&
    date &&
    desiredPeople &&
    (availableOptions === undefined || availableOptions.length == 0) ? (
    <Text className="text-sm font-BobbyJones mt-2 text-amber-50">
      No options available to fit {desiredPeople} people on selected date.
    </Text>
  ) : (
    <></>
  );
};

export default TimeField;
