import { View, Text } from "react-native";
import React from "react";

const EventDetails = ({ event }) => {
  return (
    <View className="flex-row items-center text-center m-3 bg-orange w-auto p-4 rounded-xl">
      <View className="bg-[#fcefde] p-2 rounded-2xl text-center w-24 mr-2">
        {event.day.split(" ").map((word) => (
          <Text
            className={`font-BobbyJones text-black ${
              /^-?\d+$/.test(word) ? "text-4xl" : "text-2xl"
            }`}
          >
            {word}
          </Text>
        ))}
      </View>

      <View className="flex flex-col w-80">
        <Text className="font-BobbyJones text-black text-lg">
          {event.event}
        </Text>
        {event.time && (
          <Text className="font-BobbyJones text-black text-lg">
            {event.time}
          </Text>
        )}
      </View>
    </View>
  );
};

export default EventDetails;
