import * as React from "react";
import { View, Text } from "react-native";
import Page from "../components/Page";
import { storage } from "../FireBase";
import { ref, getBytes } from "firebase/storage";

export default function FAQ({ navigation }) {
  const [FAQ, setFAQ] = React.useState(null);

  React.useEffect(() => {
    getFAQ();
  }, []);

  const getFAQ = async () => {
    console.log("Fetching FAQ file.");
    const csvtojson = require("csvtojson");

    const env = !window.location.href.includes("saunasocial.co")
      ? "sauna-social-dev"
      : "sauna-social";

    const fileRef = ref(storage, `gs://${env}.appspot.com/FAQ.csv`);

    const res = await getBytes(fileRef);
    const enc = new TextDecoder("utf-8");
    const csvStr = enc.decode(res);

    const csvAsJson = await csvtojson().fromString(csvStr);
    const FAQ = csvAsJson.map((item) => ({
      question: item.question
        .replaceAll('"', "")
        .replaceAll("”", "")
        .replaceAll("“", "")
        .replaceAll("'", ""),
      answer: item.answer
        .replaceAll('"', "")
        .replaceAll("”", "")
        .replaceAll("“", "")
        .replaceAll("'", ""),
    }));
    console.log("FAQ: ", FAQ);

    setFAQ(FAQ);
  };

  return (
    <Page
      navigation={navigation}
      body={
        <View className="items-center">
          <View className="mx-4 p-2 border-8 border-[#fcefde] rounded-2xl mb-7 max-w-4xl bg-orange">
            <Text className="font-BobbyJones text-black mb-3 text-3xl">
              FAQ{"\n"}
            </Text>

            {FAQ ? (
              FAQ.map((details) => (
                <>
                  <Text className="font-BobbyJones text-lg font-bold underline">
                    {details.question}
                  </Text>
                  <Text className="font-BobbyJones text-sm mb-4">
                    {details.answer}
                  </Text>
                </>
              ))
            ) : (
              <Text className="font-BobbyJones text-sm mb-4">Loading...</Text>
            )}
          </View>
        </View>
      }
    />
  );
}
