// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const isLocal = !window.location.href.includes("saunasocial.co");
// const isLocal = true;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = isLocal
  ? {
      apiKey: "AIzaSyCGDZ2YQ5OuMhZ_1XmfDsM-GuxmVZW1img",
      authDomain: "sauna-social-dev.firebaseapp.com",
      projectId: "sauna-social-dev",
      storageBucket: "sauna-social-dev.appspot.com",
      messagingSenderId: "783461000782",
      appId: "1:783461000782:web:6a8fc22ca90373f6da451f",
      measurementId: "G-15TFXRH9NE",
    }
  : {
      apiKey: "AIzaSyD7djecSIzCtspsoMtbPWm0cBZgd1Le-kk",
      authDomain: "sauna-social.firebaseapp.com",
      projectId: "sauna-social",
      storageBucket: "sauna-social.appspot.com",
      messagingSenderId: "435037751487",
      appId: "1:435037751487:web:02336193fa5cc40c0a9e0a",
      measurementId: "G-MGWDVJR7R6",
    };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export { storage };

export default getFirestore();
