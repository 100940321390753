import { View, Text, TouchableOpacity } from "react-native";
import React from "react";

const isLocal = !window.location.href.includes("saunasocial.co");

const MONTHLY_SUBSCRIPTION_PAYMENT_ID = isLocal
  ? "price_1OV2JkEEFNmAFqxh82jEPiyc"
  : "price_1O18EeEEFNmAFqxhGSkAoSF7";
const ANNUAL_SUBSCRIPTION_PAYMENT_ID = isLocal
  ? "price_1MDuo0EEFNmAFqxhliNDrpBi"
  : "price_1M8ruhEEFNmAFqxhKizkbyXa";

const MembershipOptions = ({ handlePayment }) => {
  return (
    <View className="flex-col justify-center h-64 w-full bg-orange">
      <Text className="font-BobbyJones text-lg text-[#fcefde] text-center">
        Choose a membership Option:
      </Text>

      <View className="flex-row justify-center">
        <TouchableOpacity
          className="h-32 w-32 justify-center m-2 p-4 bg-black"
          onPress={() => handlePayment(MONTHLY_SUBSCRIPTION_PAYMENT_ID)}
        >
          <View className="flex-col">
            <Text className=" text-white font-BobbyJones text-2xl">
              Monthly
            </Text>
            <Text className=" text-white font-BobbyJones text-2xl">$70</Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          className="h-32 w-32 justify-center m-2 p-4 bg-black"
          onPress={() => handlePayment(ANNUAL_SUBSCRIPTION_PAYMENT_ID)}
        >
          <View className="flex-col">
            <Text className=" text-white font-BobbyJones text-2xl">Annual</Text>
            <Text className=" text-white font-BobbyJones text-2xl">$500</Text>
          </View>
        </TouchableOpacity>
      </View>

      <Text className="font-BobbyJones text-sm  text-[#fcefde] text-center">
        Note: You will need to resubmit this booking after creating a new
        membership. To apply multiple memberships, each member must schedule
        their own booking.
      </Text>
    </View>
  );
};

export default MembershipOptions;
