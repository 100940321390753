import { View, Text } from "react-native";
import React from "react";

const CouponDetails = ({ coupon }) => {
  return (
    <View className="flex-row items-center m-3 bg-orange w-80 p-4 rounded-xl">
      <Text className="font-BobbyJones mr-2 text-[#fcefde] text-2xl">
        DEAL:
      </Text>

      <View className="bg-[#fcefde] p-2">
        <Text className="font-BobbyJones mr-2 text-black text-2xl rounded-xl">
          "{coupon["name"]}" - {coupon["percent_off"]}% off
        </Text>
      </View>
    </View>
  );
};

export default CouponDetails;
