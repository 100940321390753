import React from "react";
import { Text } from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { View, Image, TouchableOpacity } from "react-native";

const Header = ({ navigation }) => {
  const isLocal = !window.location.href.includes("saunasocial.co");

  return (
    <View className=" w-screen h-30 flex-row justify-center align-middle items-center relative mb-2">
      <Text className=" text-3xl font-BobbyJones text-orange">Sauna</Text>
      <TouchableOpacity
        onPress={() => {
          if (!window.location.href.includes("https://saunasocial.co")) {
            window.location = `${window.location}/Home`;
          } else {
            navigation.navigate("Home");
          }
        }}
      >
        <Image
          source={{ uri: require("../assets/logo-bus.png") }}
          resizeMode="contain"
          className="h-32 w-52"
        />
      </TouchableOpacity>
      <Text className=" text-3xl font-BobbyJones text-orange ml-2">Social</Text>
      {isLocal && (
        <Text className=" text-3xl font-BobbyJones text-orange ml-2">
          {" "}
          - TEST!
        </Text>
      )}
      <TouchableOpacity
        className="absolute right-5 top-5"
        onPress={() => navigation.toggleDrawer()}
      >
        <FontAwesomeIcon icon={faBars} style={{ width: 20, height: 20 }} />
      </TouchableOpacity>
    </View>
  );
};

export default Header;
